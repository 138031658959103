import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"

const Laowa24 = ({ data, location }) => {
  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselInnovision.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Laowa 24"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Laowa 24mm T14 2x PeriProbe</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <p>
              Descrita como "estranha, mas genial", a Lente Probe Laowa 24mm
              f/14 da Venus Optics com encaixe PL da ARRI é uma lente
              verdadeiramente única projetada para fotos em close-up.
              Distinguida por seu cano de lente longo e fino, esta lente macro
              oferece uma ampliação máxima de 2:1 com uma distância de foco
              mínima de 1,5' e uma distância de trabalho mínima de 0,8". Mesmo
              com uma distância de trabalho tão próxima, a lente mede 1,3' de
              comprimento, coloca você a uma distância confortável para
              fotografar assuntos próximos. Além disso, maior profundidade de
              campo é oferecida quando comparada às lentes macro telefoto
              tradicionais, permitindo mais detalhes no fundo com menos
              isolamento do assunto.
            </p>
            <p>
              O design óptico inclui elementos de índice de dispersão extra
              baixa e extra-refrativos, que ajudam a suprimir aberrações
              cromáticas e esféricas para um alto grau de clareza, precisão de
              cores e nitidez. O controle de foco manual permite focar de 1,5'
              até infinito, e a frente da lente possui um anel de luz LED
              embutido para auxiliar no foco em distâncias próximas. O diâmetro
              fino de 0,2" da parte frontal do cilindro da lente também oferece
              maiores oportunidades para iluminar assuntos pela frente. Além
              disso, a parte frontal da lente é à prova d'água e permite
              trabalhar com assuntos logo abaixo da superfície da água ou em
              ambientes empoeirados ou difíceis.
            </p>
            <p>
              Esta versão "cine-mod" da lente foi otimizada para uso em vídeo e
              possui controles integradas nos anéis de abertura e foco para
              ajustar íris e focar usando um follow focus.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>Lente com encaixe PL formato Full Frame</li>
                <li>Faixa de abertura de f/14 a f/40</li>
                <li>Dois Elementos de Dispersão Extra Baixa</li>
                <li>Um elemento de extra refractivo</li>
                <li>foco 2:1 a infinito</li>
                <li>Foco mínimo de 1,5' e distância de trabalho: 0,8"</li>
                <li>Controle de Foco e Abertura Integradas</li>
                <li>Cano de lente longo, pequeno diâmetro frontal</li>
                <li>Anel de luz LED embutido com alimentação micro-USB</li>
                <li>Parte frontal do cano da lente à prova d'água</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-12" />
      <div className="h-12" />
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <div className="m-auto">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/Laowa_24mm_T14_2x_Periprobe.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      folder
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />

      <Footer />
    </>
  )
}

export default Laowa24

export const query = graphql`
  {
    allCarouselInnovision: allFile(
      filter: { relativePath: { regex: "/laowa-24-t14/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
